import { template as template_6f1d1a7da84e4670a02e90e196609a48 } from "@ember/template-compiler";
const FKLabel = template_6f1d1a7da84e4670a02e90e196609a48(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
