import { template as template_751e8bfd6a7741098578bafec8448a2f } from "@ember/template-compiler";
import dirSpan from "discourse/helpers/dir-span";
import i18n from "discourse-common/helpers/i18n";
const TopicExcerpt = template_751e8bfd6a7741098578bafec8448a2f(`
  {{#if @topic.hasExcerpt}}
    <a href={{@topic.url}} class="topic-excerpt">
      {{dirSpan @topic.escapedExcerpt htmlSafe="true"}}

      {{#if @topic.excerptTruncated}}
        <span class="topic-excerpt-more">{{i18n "read_more"}}</span>
      {{/if}}
    </a>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TopicExcerpt;
