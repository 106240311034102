import { template as template_93665d956d67447fb992b78d8e9786ef } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_93665d956d67447fb992b78d8e9786ef(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
