import { template as template_0d0cd41652f6497ea84bb2d7c879e15c } from "@ember/template-compiler";
const WelcomeHeader = template_0d0cd41652f6497ea84bb2d7c879e15c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
